<template>
  <div class="row">
    <div class="col-lg-6">
      <el-checkbox class="gap-2 rounded-0" label=" إغلاق الموقع؟ "
                   :value="managementData.close_website"
                   @input="$emit('handleUpdate', 'close_website', !managementData.close_website)"
                   :checked="managementData.close_website === 1"
                 ></el-checkbox>

      <ReusableField label-text="عنوان رسالة الاغلاق" class="mt-2" :errors="errors"
                     error-message="الرجاء ادخال عنوان رسالة الاغلاق"
                     @update="handleChange" input-key="closing_message_title" :prev_val="managementData.closing_message_title"/>


      <div class="mt-3">
        <label for="content_of_closing_message">محتوى رسالة الاغلاق</label>
        <textarea :value="managementData.content_of_closing_message"
                  @input="e=>$emit('handleUpdate','content_of_closing_message',e.target.value)"
                  rows="5" id="content_of_closing_message"
                  required placeholder="محتوى رسالة الاغلاق" name="content_of_closing_message"
                  class="form-control  form-control-solid"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import ReusableField from "@/components/formControl/reusableField";

export default {
  name: "Management",
  components: {ReusableField},
  props     : ["managementData", "errors", "handleChange"],
  data() {
    return {
      close_website: this.managementData.close_website
    }
  },
  watch: {
    close_website: function (newVal) {
      this.$emit('handleUpdate','close_website',newVal)
    }
  }
}

</script>
<style scoped>
.row :deep(.form-control) {
  width: 445px;
  max-width: 100%;
}
</style>
