<template>
  <div class="row">

    <div class="col-lg-7">
      <div class="row">
        <div class="col-lg-6 ltr-control">
          <ReusableField label-text="البريد الالكتروني" :errors="errors" error-message="الرجاء ادخال البريد الالكتروني"
                         @update="handleChange" input-key="email" :prev_val="contactData.email"/>
        </div>

        <div class="col-lg-6">
          <ReusableField label-text="رقم الهاتف" :errors="errors" error-message="الرجاء ادخال رقم الهاتف"
                         @update="handleChange" input-key="phone_number" :prev_val="contactData.phone_number"/>
        </div>
        <div class="col-lg-6">
          <ReusableField label-text="رقم الجوال" :errors="errors" error-message="الرجاء ادخال رقم الجوال"
                         @update="handleChange" input-key="mobile_number" :prev_val="contactData.mobile_number"/>
        </div>
        <div class="col-lg-6">
          <ReusableField label-text="العنوان" :errors="errors" error-message="الرجاء ادخال العنوان"
                         @update="handleChange" input-key="address" :prev_val="contactData.address"/>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import ReusableField from "@/components/formControl/reusableField";

export default {
  name      : "ContactInfo",
  components: {ReusableField},
  props     : ["contactData", "errors", "handleChange"]
}
</script>

<style scoped>
.row :deep(.form-control) {
  width: 445px;
  max-width: 100%;
}
</style>
