<template>
  <div class="row">
    <div class="col-lg-7">
      <div class="row">
        <div class="form-group">
          <label for="google_view_id">Google view id</label>
          <input type="text" :value="data.google_view_id"
                 @input="e=>$emit('handleUpdate','google_view_id',e.target.value)"
                 name="google_view_id"  id="google_view_id" class="form-control form-control-solid"
                 :disabled="getCurrentUser.type_id !== '1'"
          >
        </div>

        <div class="form-group mt-3">
          <label for="header_scripts">header scripts</label>
          <textarea  :value="data.header_scripts"
                     @input="e=>$emit('handleUpdate','header_scripts',e.target.value)"
                    rows="6" id="header_scripts"
                    required placeholder="..." name="header_scripts"
                    class="form-control w-100 form-control-solid"></textarea>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import ReusableField from "@/components/formControl/reusableField";
import {mapGetters} from "vuex";
export default {
  name: 'trackingCode',
  components: {ReusableField},
  props: ["data","handleChange"],
  computed: {
    ...mapGetters(['getCurrentUser'])
  },

}
</script>

<style scoped>
#header_scripts,
#google_view_id {
  direction: ltr;
  text-align: left;
}

input.form-control {
  max-width: 400px;
}

.form-control:disabled {
  cursor: not-allowed;
}

</style>
