<template>

  <div class="row">
    <div class="col-lg-7">
      <div class="row">
        <div class="col-lg-6">
          <ReusableField label-text="اسم الموقع" :errors="errors" error-message="الرجاء ادخال اسم الموقع" @update="handleChange"
                         input-key="socialmedia_name" :prev_val="socialData.socialmedia_name"/>
        </div>
        <div class="col-lg-6 ltr-control">
          <ReusableField label-text="حساب تويتر"  @update="handleChange" :notRequired="true"
                         input-key="twitter_account" :prev_val="socialData.twitter_account"/>
        </div>



        <div class="col-lg-6 mt-3 ltr-control">
          <ReusableField label-text="حساب قوقل بلس"   @update="handleChange" :notRequired="true"
                         input-key="google_account" :prev_val="socialData.google_account"/>
        </div>

        <div class="col-lg-6 mt-3 ltr-control">
          <ReusableField label-text="حساب الانستقرام"  @update="handleChange" :notRequired="true"
                         input-key="instagram_account" :prev_val="socialData.instagram_account"/>
        </div>

        <div class="col-lg-4 mt-3 ltr-control">
          <ReusableField label-text="حساب فيسبوك"  @update="handleChange" :notRequired="true"
                         input-key="facebook_account" :prev_val="socialData.facebook_account"/>
        </div>

        <div class="col-lg-4 mt-3 ltr-control">
          <ReusableField label-text=" رقم حساب فيسبوك"  @update="handleChange" :notRequired="true"
                         input-key="facebook_app_number" :prev_val="socialData.facebook_app_number"/>
        </div>

        <div class="col-lg-4 mt-3 ltr-control">
          <ReusableField label-text=" توكن فيسبوك"   @update="handleChange" :notRequired="true"
                         input-key="facebook_access_token" :prev_val="socialData.facebook_access_token"/>
        </div>

        <div class="col-lg-4 mt-3 ltr-control">
          <ReusableField label-text="رقم الواتساب"  @update="handleChange" :notRequired="true"
                         input-key="whatsapp_number" :prev_val="socialData.whatsapp_number"/>
        </div>


        <div class="col-lg-4 mt-3 ltr-control">
          <ReusableField label-text="رقم التلجرام"  @update="handleChange" :notRequired="true"
                           input-key="telegram_number" :prev_val="socialData.telegram_number"/>
        </div>

        <div class="col-lg-4 mt-3 ltr-control">
          <ReusableField label-text="حساب اليوتيوب" :notRequired="true" @update="handleChange"
                         input-key="youtube_account" :prev_val="socialData.youtube_account"/>
        </div>

        <div class="col-lg-12 mt-3">
          <label for="socialmedia_description">وصف الموقع</label>
          <textarea v-model="socialData.socialmedia_description"
                    rows="5" id="socialmedia_description"
                    required placeholder="وصف الموقع" name="socialmedia_description"
                    class="form-control w-100 form-control-solid"></textarea>
        </div>
      </div>

    </div>
    <div class="col-lg-3">
      <div class="form-group news-image logo">
        <label class="mb-0">صورة الموقع</label>
        <label
            class="placeholder-image mt-3"
            @click="handleOpenUploadCenter('default_news_image')"
        >
          <img
              class="w-100"
              height="220"
              :src=" !socailmedia_image ? socialData.socailmedia_image : APP_URL + socailmedia_image"
              alt=""
          />

        </label>

      </div>
    </div>
    <el-dialog
        width="70%"
        @close="closeUploadCenter"
        :destroy-on-close="true"
        title="مركز الرفع"
        v-model="showUploadCenter"
    >
      <UploadCenter
          :is-single-upload="isSingleUpload"
          @save="handleAddPhoto"
      />
    </el-dialog>
  </div>

</template>

<script>
import ReusableField from "@/components/formControl/reusableField";
import UploadCenter from "@/components/uploadCenter";

export default {
  name: "SocialMediaSettings",
  components: {ReusableField,  UploadCenter},
  props: ["socialData","errors","handleChange"],
  data() {
    return {
      isSingleUpload    : false,
      showUploadCenter  : false,
      socailmedia_image: ''
    }
  },
  computed: {
    APP_URL: function () {
      return process.env.VUE_APP_IMAGE_REMOTE_URL;
    },
  },
  methods: {
    handleOpenUploadCenter()
    {
      this.showUploadCenter = true;
      this.isSingleUpload   = true;
    },
    closeUploadCenter()
    {
      this.showUploadCenter = false;
      this.isSingleUpload   = false;
    },
    handleAddPhoto(photo)
    {
      if (this.showUploadCenter && this.isSingleUpload)
      {
        this.socailmedia_image = photo.url;
        this.$emit("update", "image", photo.url);
      }
      this.closeUploadCenter();
    },
  }
}
</script>
<style scoped>
.row :deep(.form-control) {
  width: 445px;
  max-width: 100%;
}
</style>
