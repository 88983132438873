<template>
  <div class="row">
    <div class="col-lg-8">
      <div class="row">
        <div class="col-lg-4">
          <div class="form-group">
            <label for="no_of_main_news">عدد الأخبار الرئيسية</label>
            <input name="no_of_main_news" id="no_of_main_news"
                   class="form-control" type="number" min="1"
                   max="6" required
                   :value="settingsData.no_of_main_news"
                   @input="$emit('handleUpdate', 'no_of_main_news', $event.target.value)"
            />
          </div>
        </div>

        <div class="col-lg-4 ltr-control">
          <ReusableField label-text="رابط الموقع"  :errors="errors" error-message="الرجاء ادخال رابط الموقع"
                         input-key="website_url" :prev_val="settingsData.website_url" @update="handleChange"/>
        </div>

        <div class="col-lg-4">
          <ReusableField label-text="اسم الموقع" :errors="errors" error-message="الرجاء ادخال اسم الموقع"
                         input-key="website_name" :prev_val="settingsData.website_name" @update="handleChange"/>
        </div>

        <div class="col-lg-6 mt-2">
          <ReusableField label-text="كلمات مفتاحية" :errors="errors" error-message="الرجاء ادخال كلمات مفتاحية"
                         input-key="keywords" :prev_val="settingsData.keywords" @update="handleChange"/>
        </div>

        <div class="col-lg-6 mt-2">
          <ReusableField label-text="حقوق النشر" :errors="errors" error-message="الرجاء ادخال حقوق النشر"
                         input-key="copyrights" :prev_val="settingsData.copyrights" @update="handleChange"/>
        </div>

        <div class="col-lg-12 mt-2">
          <label for="about">عن الموقع</label>
          <textarea
                    :value="settingsData.about"
                    @input="e=>$emit('handleUpdate','about',e.target.value)"
                    rows="5" id="about"
                    required placeholder="عن الموقع" name="about"
                    class="form-control w-100 form-control-solid"></textarea>
        </div>

        <div class="col-lg-4 mt-3">
          <div class="form-group news-image logo">
            <label class="mb-0">الشعار</label>
            <label
                class="placeholder-image mt-2"
                @click="handleOpenUploadCenter('logo')"
            >
              <img
                  class="w-100 bg-white"
                  height="220"
                  :src=" !logo ? settingsData.logo : APP_URL + logo"
                  alt=""
              />

              <!--            <img src="/media/cloud-img.png" v-else class="img-float" alt=""/>-->
            </label>
          </div>
        </div>

        <div class="col-lg-4 mt-3">
          <div class="form-group news-image logo">
            <label class="mb-0">أيقونة الموقع</label>
            <label
                class="placeholder-image mt-2"
                @click="handleOpenUploadCenter('favourite_icon')"
            >
              <img
                  class="w-100 bg-white"
                  height="220"
                  :src=" !favourite_icon  ? settingsData.favourite_icon : APP_URL + favourite_icon"
                  alt=""
              />
            </label>
          </div>
        </div>


      </div>
    </div>

    <div class="col-lg-4">
      <div class="form-group news-image logo">
        <label class="mb-0">صورة افتراضية للأخبار</label>
        <label
            class="placeholder-image mt-3"
            @click="handleOpenUploadCenter('default_news_image')"
        >
          <img
              class="w-100"
              height="220"
              :src=" !default_news_image ? settingsData.default_news_image : APP_URL + default_news_image"
              alt=""
          />

        </label>

      </div>


      <div class="form-group ltr-control">
        <el-checkbox
            :value="settingsData.use_watermarks"
            @input="$emit('handleUpdate', 'use_watermarks', !settingsData.use_watermarks)"
            :checked="settingsData.use_watermarks === 1"
            class="me-0 mb-3  rounded-0" label="استخدام علامة مائية؟"
                     border></el-checkbox>

        <ReusableField v-if="settingsData.use_watermarks" label-text="رابط العلامة المائية" :errors="errors"
                       error-message="الرجاء ادخال رابط العلامة المائية" @update="handleChange"
                       input-key="watermakrs_url" :prev_val="settingsData.watermakrs_url"/>

      </div>

      <el-dialog
          @close="closeUploadCenter"
          :destroy-on-close="true"
          title="مركز الرفع"
          v-model="showUploadCenter"
      >
        <UploadCenter
            :is-single-upload="isSingleUpload"
            @save="handleAddPhoto"
        />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {Field} from "vee-validate";
import ReusableField from "@/components/formControl/reusableField";
import UploadCenter from "@/components/uploadCenter";

export default {
  name      : "GeneralSettings",
  props     : ["errors", "handleChange", "settingsData"],
  components: {
    Field,
    ReusableField,
    UploadCenter,
  },
  data()
  {
    return {
      logo              : '',
      favourite_icon    : '',
      default_news_image: '',
      isSingleUpload    : false,
      showUploadCenter  : false,
      image_type        : '',

    }
  },
  computed: {
    APP_URL: function () {
      return process.env.VUE_APP_IMAGE_REMOTE_URL;
    },
  },
  watch   : {
    use_watermark: function () {
      this.$emit('handleUpdate', 'use_watermarks', !this.settingsData.use_watermarks)
    },
    logo: function () {
      this.$emit('handleUpdate', 'logo', this.APP_URL + this.logo)
    },
    default_news_image: function () {
      this.$emit('handleUpdate', 'default_news_image', this.APP_URL + this.default_news_image)
    },
    favourite_icon: function () {
      this.$emit('handleUpdate', 'favourite_icon', this.APP_URL + this.favourite_icon)
    },


  },
  methods : {
    isRequired(value)
    {
      return value ? true : 'This field is required';
    },
    handleOpenUploadCenter(type)
    {
      this.showUploadCenter = true;
      this.isSingleUpload   = true;
      this.image_type       = type
    },
    closeUploadCenter()
    {
      this.showUploadCenter = false;
      this.isSingleUpload   = false;
    },
    handleAddPhoto(photo)
    {
      if (this.showUploadCenter && this.isSingleUpload)
      {
        this[this.image_type] = photo.url;
        this.$emit("handleUpdate", "image", photo.url);
      }
      this.closeUploadCenter();
    },
  }
}
</script>

<style scoped lang="scss">
.logo {
  .placeholder-image img.w-100 {
    object-fit: contain;

  }
}

.el-checkbox.is-bordered {
  margin: 0;
  border-radius: 0;
  height: 38px;
}

.el-checkbox__label {
  padding-right: 8px;
}
</style>
