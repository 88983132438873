import request from "@/utils/request";

export function getSiteSettings(){
  return request(
    {
      url: 'settings',
      method: 'get'
    }
  )
}

export function updateSettings(data) {
  return request(
    {
      url: "settings/update",
      method: "post",
      data
    }
  )
}
