<template>
  <div class="container-fluid multiple-tabs mt-3">
    <Form @submit="updateSettings" id="settingsForm" v-slot="{ isSubmitting ,errors }">
      <div class="d-flex  justify-content-between align-items-center flex-wrap">
        <Breadcrumb current_page="إعدادات الموقع"/>
        <div class="controls-btns d-flex gap-2 ">
          <button class="btn btn-publish" type="submit" :disabled="isSubmitting">حفظ</button>
        </div>
      </div>
<!--      <div class="alert d-inline-block alert-danger" v-if="Object.keys(errors).length">-->
<!--        {{errors}}-->
<!--      </div>-->
      <el-card class="custom-card mt-3">
        <el-tabs v-model="activeTab" v-if="!loading">
          <el-tab-pane label="إعدادات الموقع" name="siteSettings">
            <general-settings :errors="errors" :settings-data="settings" @handleUpdate="handleOnChange"
                              :handle-change="handleOnChange"/>
          </el-tab-pane>
          <el-tab-pane label="الإدارة العليا" v-if="getCurrentUser.type_id === '1'" name="management">
            <management :errors="errors" :management-data="settings" @handleUpdate="handleOnChange"
                        :handle-change="handleOnChange"/>
          </el-tab-pane>
          <el-tab-pane label="معلومات التواصل" name="contactInfo">
            <contact-info :contact-data="settings" :errors="errors" :handle-change="handleOnChange"/>
          </el-tab-pane>
          <el-tab-pane label="خصائص محركات البحث" name="seoFeature">
            <SEO :seo-data="settings" :errors="errors" @handleUpdate="handleOnChange" :handle-change="handleOnChange"/>
          </el-tab-pane>
          <el-tab-pane label="الشبكات الاجتماعية" name="socialMedia">
            <social-media-settings :social-data="settings" :errors="errors" @handleUpdate="handleOnChange"
                                   :handle-change="handleOnChange"/>
          </el-tab-pane>

          <el-tab-pane label="أكواد التتبع" name="trackingCode">
            <tracking-code :data="settings"  @handleUpdate="handleOnChange" :handle-change="handleOnChange"/>
          </el-tab-pane>

        </el-tabs>

        <Loader v-else/>
      </el-card>
    </Form>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import {Field, Form} from "vee-validate";
import UploadCenter from "@/components/uploadCenter";
import ReusableField from "@/components/formControl/reusableField";
import {getSiteSettings, updateSettings} from "@/services/settings";
import GeneralSettings from "@/views/settings/components/generalSettings";
import Loader from "@/components/Loader";
import ContactInfo from "@/views/settings/components/contactInfo";
import SEO from "@/views/settings/components/SEO";
import SocialMediaSettings from "@/views/settings/components/socialMediaSetting";
import Management from "@/views/settings/components/management";
import {useToast} from 'vue-toastification'
import {mapGetters} from "vuex";
import TrackingCode from "./components/trackingCode";

export default {
  name      : "Settings",
  components: {
    TrackingCode,
    Management,
    SocialMediaSettings,
    SEO,
    ContactInfo,
    Loader,
    GeneralSettings,
    Breadcrumb,
    Form,
    Field,
    UploadCenter,
    ReusableField
  },
  data()
  {
    return {
      toast    : useToast(),
      loading  : false,
      activeTab: 'siteSettings',
      settings : {},
    }
  },
  computed: {
    ...mapGetters(['getCurrentUser'])
  },
  mounted()
  {
    this.fetchSettings();
  },
  methods: {
    handleOnChange(key, val)
    {
      this.settings = {
        ...this.settings,
        [key]: val
      }
    },
    fetchSettings()
    {
      this.loading = true
      getSiteSettings()
          .then(res => {
            res.data.use_watermarks = Number(res.data.use_watermarks)
            res.data.close_website = Number(res.data.close_website)
            this.settings = res.data
            this.loading  = false
          })
          .catch(() => this.loading = false)
    },
    async updateSettings()
    {
      try
      {
        const result   = await updateSettings(this.settings)
        this.toast.success('تم التعديل بنجاح', {position: 'top-center'})
      } catch (e)
      {
        console.log(e)
      }

    }
  }
}
</script>

<style scoped>

.form-control {
  width: 445px;
  max-width: 100%;
}
</style>
