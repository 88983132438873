<template>

  <div class="row ">
    <div class="col-lg-4">
      <ReusableField label-text="اسم الموقع على محركات البحث" :errors="errors"
                     error-message="الرجاء ادخال اسم الموقع على محركات البحث"
                     @update="handleChange" input-key="name_on_search_engines" :prev_val="seoData.name_on_search_engines"/>

      <ReusableField label-text="عنوان الموقع على محركات البحث" :errors="errors" class="mt-2"
                     error-message="الرجاء ادخال عنوان الموقع على محركات البحث"
                     @update="handleChange" input-key="display_news_title_search_engine" :prev_val="seoData.display_news_title_search_engine"/>



   <ReusableField label-text="رابط شعار الموقع" class="mt-2" :errors="errors" error-message=""
                     @update="handleChange" input-key="website_motto" :prev_val="seoData.website_motto"/>

      <div class="col-lg-12 mt-2">
        <label for="about">عن الموقع</label>
        <textarea
            :value="seoData.website_description_search_engine"
            @input="e=>$emit('handleUpdate','website_description_search_engine',e.target.value)"
            rows="5" id="about"
            required placeholder="عن الموقع" name="about"
            class="form-control  form-control-solid"></textarea>
      </div>

      <div>
        <el-checkbox v-model="rss" class="me-0 mt-3 mb-0 rounded-0" label="السماح باستخدام خلاصات  الموقع؟"
        ></el-checkbox>
      </div>
      <div>
        <el-checkbox v-model="robots_content" class="me-0 my-1 rounded-0" label="إنشاء ملف الروبوت؟"
        ></el-checkbox>
      </div>
      <el-checkbox v-model="using_large_image_on_twitter" class="me-0 rounded-0"
                   label="استخدام الصورة الكبيرة في عرض بطاقات تويتر؟"
      ></el-checkbox>

    </div>

    <div class="col-lg-6">
      <ReusableField label-text="اسم الموقع في اخبار قوقل"  :errors="errors"
                     error-message="الرجاء ادخال اسم الموقع في اخبار قوقل"
                     :update="handleChange" input-key="name_on_google_news" :prev_val="seoData.name_on_google_news"/>

      <ReusableField label-text="العلامة الفاصلة في الرابط" class="mt-2" :errors="errors" error-message=""
                     @update="handleChange" input-key="separator_used_in_url" :prev_val="seoData.separator_used_in_url"/>

      <div class="col-lg-12 mt-2">
        <label for="about">Robots file</label>
        <input type="text"
               class="form-control"
               :value="seoData.robotes_content"
               @input="e=>$emit('handleUpdate','robotes_content',e.target.value)"
        >
      </div>
    </div>
  </div>

</template>

<script>
import ReusableField from "@/components/formControl/reusableField";

export default {
  name      : "SEO",
  components: {ReusableField},
  props     : ["seoData", "errors", "handleChange"],
  data()
  {
    return {
      rss                         : this.seoData.rss,
      robots_content              : this.seoData.robots_content,
      using_large_image_on_twitter: this.seoData.using_large_image_on_twitter
    }
  },
  watch: {
    rss: function (newVal) {
      this.$emit('handleUpdate', 'rss', newVal)
    },
    robots_content: function (newVal) {
      this.$emit('handleUpdate', 'robotes_content', newVal)
    },
    using_large_image_on_twitter: function (newVal) {
      this.$emit('handleUpdate', 'using_large_image_on_twitter', newVal)
    },
  },
  methods: {
    handleUploadFile(e){
      //console.log(this.files)
      //console.log(e)

    }
  }
}
</script>
<style scoped>
.row :deep(.form-control) {
  width: 445px;
  max-width: 100%;
}
</style>
